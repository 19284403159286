
import { defineComponent } from 'vue';
import { listPagination } from '@/components/common/list';
import AddButton from '@/components/common/add-button/index.vue';
import initRfCard from '@/views/pm/old-community/delivery-auth/module/init-rf-card';

export default defineComponent({
    components: {
        listPagination,
        AddButton
    },
    setup() {
        const {
            requestData,
            updateList,
            saveParamInPath,
            searchList,
            add,
            edit,
            headers,
            searchKeyList
        } = initRfCard();

        return {
            searchKeyList,
            requestData,
            updateList,
            saveParamInPath,
            searchList,
            add,
            edit,
            headers
        };
    }
});
